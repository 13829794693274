import "../styles/index.css"
import "../styles/syntax-highlighting.css"
import * as Turbo from "@hotwired/turbo"

/**
 * Adds support for declarative shadow DOM. Requires your HTML <head> to include:
 * `<meta name="turbo-cache-control" content="no-cache" />`
 */
import * as TurboShadow from "turbo-shadow"

import "./turbo_transitions.js"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

import "@fontsource-variable/readex-pro"
import "@fontsource-variable/fira-code"

console.info("Bridgetown is loaded!")
